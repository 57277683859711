<template>
  <div>
    <v-card style="padding: 10px">
      <v-form>
        <v-row>
          <v-col cols="12" md="3">
            <label for="inventory_address">{{ translate("Magazine Name") }}</label>
          </v-col>

          <v-col cols="12" md="9">
            <v-text-field
              id="inventory_name"
              v-model="inventory_name"
              outlined
              dense
              :placeholder="translate('Magazine Name')"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="town">{{ translate("Town") }}</label>
          </v-col>

          <v-col cols="12" md="9">
            <v-text-field
              id="Town"
              v-model="inventory_town"
              outlined
              dense
              :placeholder="translate('Town')"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="inventory_address">{{ translate("Address") }}</label>
          </v-col>

          <v-col cols="12" md="9">
            <v-text-field
              id="Inventory Address"
              v-model="inventory_address"
              outlined
              dense
              :placeholder="translate('Address')"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <label for="inventory_address">{{ translate("Phone Number") }}</label>
          </v-col>

          <v-col cols="12" md="9">
            <v-text-field
              id="phone_number"
              v-model="phone_number"
              outlined
              dense
              :placeholder="translate('Phone Number')"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col>
            <label for="inventory_address"
              >{{ translate("Magazine Location on Map") }}:-</label
            >

            <template>
              <l-map
                style="height: 500px"
                :zoom="zoom"
                :center="center"
                @click="mapclick"
              >
                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                <l-marker :lat-lng="markerLatLng">
                  <l-tooltip :options="{ permanent: true }">
                    {{ inventory_name }}
                  </l-tooltip>
                </l-marker>
              </l-map>
            </template>
          </v-col>
          <v-col offset-md="3" cols="12">
            <v-btn color="primary" v-on:click="submit"> {{ translate("Submit") }} </v-btn>
            <v-btn v-on:click="cancel" type="reset" class="mx-2" outlined>
              {{ translate("Cancel") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import { ref } from "@vue/composition-api";
import Leaflet from "easy-vue-leaflet";
import axios from "axios";
import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";
import { Icon } from "leaflet";
import { editMagazine, getMagazines } from "@/ApiManager";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default {
  props: ["id"],
  components: {
    leaflet: Leaflet,
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 6,
      center: [this.$route.query.lat, this.$route.query.lng],
      markerLatLng: [this.$route.query.lat, this.$route.query.lng],
      leaflet_key: 0,
      inventory_name: this.$route.query.name,
      inventory_address: this.$route.query.address,
      phone_number: this.$route.query.phone_number,
      inventory_town: this.$route.query.town,
    };
  },

  methods: {
    mapclick(data) {
      this.markerLatLng = [data.latlng["lat"], data.latlng["lng"]];
    },
    cancel: function (_) {
      this.$router.go(-1);
    },
    submit: function (_) {
      //get the first marker from the list
      var lat = this.markerLatLng[0];
      var lng = this.markerLatLng[1];
      var inventoryname = this.inventory_name;
      var inventoryaddress = this.inventory_address;
      var phone_number = this.phone_number;
      editMagazine(
        this.$route.query.id,
        inventoryname,
        inventoryaddress,
        this.inventory_town,
        lat,
        lng,
        phone_number
      ).then((response) => {
        this.$router.push({ name: "magazines" });
      });
    },
  },
  setup() {
    const inventory_name = ref("");
    const leaflet_key = ref("");
    const inventory_address = ref("");
    const phone_number = ref("");
    const inventory_town = ref("");

    return {};
  },
};
</script>
<style>
@import url("https://unpkg.com/leaflet@1.6.0/dist/leaflet.css");
</style>
